import { getBlob } from './http';

export const downloadCsv = async (studyId) => {
  try {
    const response = await getBlob(`study/${studyId}/supplies-report`);
    // Create a Blob from the response
    const blob = new Blob([response.data], { type: 'text/csv' });

    // Create a link element to download the file
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'records.csv'; // File name
    document.body.appendChild(link);
    link.click();

    // Clean up the URL object
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading CSV:', error);
    throw error; // Optionally, propagate the error
  }
};
