import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";

import { useSelector } from "react-redux";
import { Formik } from "formik";
import {
  Button,
  HemaLabel,
  HemaValue,
  FormText,
  FormSwitch,
  FormMultiSelect,
} from "../../../utils";
import { buildKitItemDetailAction } from "../../../Actions/kitBuilder";
import Skeleton from "react-loading-skeleton";
import {
  Cancel,
  Add,
  LabelQuantity,
  CrossIcon,
  UserGroup,
  ExpiryNotification,
  Print,
} from "../../../HemeIconLibrary";
import { useDispatch } from "react-redux";
import { setForm } from "../../../Store/reducers/uiSettings";
import { HemaDateFormat } from "../../../helpers/commonHelper";
import { downloadDocumentAction } from "../../../Actions/documentTemplate";
import { Dropdown, Spinner } from "react-bootstrap";
import StorageLocationForm from "./StorageExporer";
import {
  editFormReducer,
  setFormLoaderReducer,
  showSuccessReducer,
} from "../../../Store/reducers/uiSettings";
import {
  buildNewKitAction,
  getKitBuilderAction,
} from "../../../Actions/kitBuilder";
import { printKitLabelAction } from "../../../Actions/inventory";
import KitBuilderDropDown from "../../../utils/FormElements/kitbuilderdropdown";

const EditKitBuilder = ({ cta }) => {
  const { uisettings, builder, settings } = useSelector((state) => state);
  const [loader, setLoader] = useState(false);
  const [showBuildQty, setshowBuildQty] = useState("");
  const [showStorage, setShowStorage] = useState(false);
  const [confirmSlide, setconfirmSlide] = useState(false);
  const [activeLotData, setActiveloadData] = useState();
  const [isBuild, setisBuild] = useState(false);
  const dispatch = useDispatch();
  const formikRef = useRef();
  useEffect(() => {
    (async () => {
      if (showBuildQty) {
        buildKitItemDetailAction(
          uisettings.editForm?.kitId || uisettings.editForm?.id,
          showBuildQty,
        );
        dispatch(
          editFormReducer({
            ...uisettings?.editForm,
            selectedQuantity: showBuildQty,
          }),
        );
      }
    })();
  }, [showBuildQty]);
  const componentRef = useRef();

  function downloadAsPDF(base64String, filename) {
    if (base64String.startsWith("JVB")) {
      base64String = "data:application/pdf;base64," + base64String;
      downloadFileObject(base64String, filename);
    } else if (base64String.startsWith("data:application/pdf;base64")) {
      downloadFileObject(base64String, filename);
    } else {
      alert("Not a valid Base64 PDF string. Please check");
    }
  }

  function saveAsXlsxFile(base64String, filename) {
    var mediaType =
      "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";

    var a = document.createElement("a");
    a.href = mediaType + base64String;
    //a.href = mediaType+userInp;
    a.download = filename;

    a.click();
  }

  function downloadFileObject(base64String, filename) {
    const linkSource = base64String;
    const downloadLink = document.createElement("a");

    downloadLink.href = linkSource;
    downloadLink.download = filename;
    downloadLink.click();
  }
  // build template Name
  const row = uisettings.editForm;
  useEffect(() => {
    (async () => {
      await formikRef.current?.validateForm();
    })();
  }, [JSON.stringify(formikRef?.current), uisettings?.editForm, showStorage]);

  function findClosestExpiry(objects) {
    let closestExpiry = null;
    let closestObject = null;
    const currentDate = new Date();

    for (let i = 0; i < objects.length; i++) {
      const obj = objects[i];
      const expiryDate = new Date(obj.expirationDate);

      if (
        expiryDate > currentDate &&
        (closestExpiry === null || expiryDate < closestExpiry)
      ) {
        closestExpiry = expiryDate;
        closestObject = obj;
      }
    }
    if (closestObject) {
      return closestObject;
    } else {
      return objects?.[0];
    }
  }

  const setActiveLoaderfucntion = () => {
    setActiveloadData(
      builder?.buildKit?.items?.map((data) => {
        return {
          ...data,
          lots: findClosestExpiry(data?.lots),
        };
      }),
    );
  };
  useEffect(() => {
    if (builder?.buildKit?.items) {
      setActiveLoaderfucntion();
    }
  }, [JSON.stringify(builder.buildKit)]);

  return (
    <Formik
      initialValues={{
        qty: "",
        locationId: "",
        closeToExpiryAlert: false,
        closeToExpiryNumberOfDays: "",
        userIds: [],
      }}
      // enableReinitialize
      innerRef={formikRef}
      validate={(values) => {
        const errors = {};
        if (!values?.qty) {
          errors.qty = "Required";
          setshowBuildQty("");
        } else if (values?.qty > uisettings?.editForm?.availableToBuild) {
          errors.qty = `Don't have enough quantity available`;
          setshowBuildQty("");
        } else {
          setshowBuildQty(values.qty);
        }
        if (!values.locationId?.id && !!showBuildQty) {
          errors.locationId = "Required";
        }
        if (values.closeToExpiryAlert && !values.closeToExpiryNumberOfDays) {
          errors.closeToExpiryNumberOfDays = "Required";
        }
        if (values.closeToExpiryAlert && values.userIds?.length === 0) {
          errors.userIds = "Required";
        }

        return errors;
      }}
      onSubmit={async (values) => {
        // if (confirmSlide) {
        dispatch(setFormLoaderReducer(true));
        const createNewIem = await buildNewKitAction(
          uisettings.editForm?.kitId || uisettings.editForm?.id,
          {
            qty: values.qty,
            storageLocationId: values?.locationId?.id,
            closeToExpiryAlert: values.closeToExpiryAlert,
            closeToExpiryDays: values.closeToExpiryAlert
              ? values.closeToExpiryNumberOfDays
              : undefined,
            // items: builder.buildKit?.items?.map((item) => {
            //   return item.lots.map((lot, counter) => {
            //     if (counter === 0) {
            //       return { id: lot.id };
            //     }
            //   })?.[0];
            // }),
            items: activeLotData?.map((data) => {
              return {
                id: data.lots.id,
              };
            }),
            userIds: !values?.closeToExpiryAlert
              ? undefined
              : values?.userIds?.map((data) => data.id) || [],
          },
        );
        dispatch(setFormLoaderReducer(false));
        if (createNewIem?.status === 200) {
          // dispatch(setFormCloseReducer());
          dispatch(
            editFormReducer({
              ...uisettings?.editForm,
              ...createNewIem?.data,
            }),
          );
          dispatch(
            showSuccessReducer(
              `${createNewIem?.data?.quantity} ${createNewIem?.data?.item?.name} Kit added.`,
            ),
          );
          getKitBuilderAction();
        }
        dispatch(setFormLoaderReducer(false));
        if (cta) {
          cta();
        }
        // } else {
        setconfirmSlide(true);
        // }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        setFieldTouched,
        /* and other goodies */
      }) => (
        <>
          <form
            onSubmit={handleSubmit}
            className={` ${
              showStorage && "hidden"
            } w-full h-[calc(100%-50px)] flex flex-col`}
          >
            {confirmSlide ? (
              <div className="mb-[10px] border border-solid border-[#DEE2E6] rounded">
                <DataTable
                  columns={[
                    {
                      name: "Name",
                      selector: (row) => row?.name,
                    },
                    {
                      name: "Quantity",
                      selector: (row) => row?.selectedQuantity,
                    },
                    {
                      name: "Lot Number",
                      selector: (row) => row?.lotNumber || "N/A",
                    },
                    {
                      name: "Expiration Date",
                      selector: (row) => (
                        <HemaValue
                          text={HemaDateFormat(row?.expirationDate)}
                          color="text-[#595959]"
                          className="text-xs font-medium"
                        />
                      ),
                    },
                  ]}
                  data={[uisettings.editForm]}
                />
              </div>
            ) : (
              <>
                <div className="flex flex-col gap-[15px] ">
                  <div className="flex items-center gap-[54px] meta pb-[16px] border-b border-dashed border-b-[#DEE2E6]">
                    <HemaLabel
                      type={"table"}
                      text={"Template Name"}
                      className="text-[#000000] font-medium text-sm"
                    />
                    <HemaValue
                      text={row?.name || uisettings?.editForm?.row?.item?.name}
                      className="font-medium text-[#595959] text-sm"
                    />
                  </div>

                  <HemaLabel
                    type={"table"}
                    text={"Quantity"}
                    icon={<LabelQuantity purple />}
                    required={true}
                    className={`flex`}
                    fluid={true}
                  />
                  <div className="flex gap-[15px] items-center w-[317px]">
                    <FormText
                      type="number"
                      name={"qty"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      value={values.qty}
                      placeholder={"Enter quantity"}
                      min={1}
                      step={1}
                      // disabled={fields.disabled}
                      // showTotal={fields.showTotal}
                      // onClick={fields.onClick}
                      crossIcon={values?.qty ? <CrossIcon /> : ""}
                      className="w-full"
                    />
                    {true && (
                      <HemaValue
                        className="whitespace-nowrap"
                        text={`/ ${
                          uisettings.editForm?.quantity ||
                          uisettings.editForm?.availableToBuild ||
                          0
                        }`}
                      />
                    )}
                  </div>
                  {errors.qty && touched.qty && (
                    <div className="error text-[red] text-[12px] pt-[2px]">
                      {errors.qty}
                    </div>
                  )}
                </div>
                {showBuildQty && (
                  <>
                    {builder.buildKit ? (
                      <div
                        ref={componentRef}
                        className="w-full border-b border-dashed border-[#DEE2E6] "
                      >
                        <DataTable
                          data={activeLotData || []}
                          className="kit_builder_table"
                          columns={[
                            {
                              width: "36%",
                              style: {
                                alignItems: "flex-start",
                              },
                              cell: (row) => {
                                return (
                                  <div>
                                    <div className="flex items-center gap-0 meta mb-[5px] table_lable_hed">
                                      <HemaValue
                                        text={row?.name}
                                        color="text-[#605DAF]"
                                        className="font-medium"
                                      />
                                    </div>
                                    <div className="flex items-center gap-[8px] meta mb-[5px] table_lable_hed">
                                      <HemaLabel
                                        text="Expiration Date:"
                                        color="#000000"
                                        textSize="text-[12px]"
                                        className="font-normal"
                                      />
                                      <HemaValue
                                        text={HemaDateFormat(
                                          row.lots?.expirationDate,
                                        )}
                                        color="text-[#595959]"
                                        className="text-xs font-medium"
                                      />
                                    </div>
                                    <div className="flex items-center gap-[8px] meta mb-0 table_lable_hed">
                                      <HemaLabel
                                        text="Quantity Per Kit:"
                                        color="text-[#232323]"
                                        textSize="text-[12px]"
                                        className="font-normal"
                                      />
                                      <HemaValue
                                        text={row.qtyPerKit}
                                        color="text-[#595959]"
                                        className="text-xs font-medium"
                                      />
                                    </div>
                                  </div>
                                );
                              },
                            },
                            {
                              width: "26%",
                              style: {
                                alignItems: "flex-end",
                              },
                              cell: (row) => {
                                return (
                                  <div>
                                    <div className="flex items-center gap-[8px] meta mb-0 table_lable_hed">
                                      <HemaLabel
                                        text="Total Quantity:"
                                        color="text-[#232323]"
                                        textSize="text-[12px]"
                                        className="font-normal"
                                      />
                                      <HemaValue
                                        text={row.totalQty}
                                        color="text-[#595959]"
                                        className="text-xs font-medium"
                                      />
                                    </div>
                                  </div>
                                );
                              },
                            },
                            {
                              width: "38%",
                              style: {
                                alignItems: "stretch",
                              },
                              selector: (row) => row.year,
                              grow: 3,
                              cell: (row) => {
                                return (
                                  <div className="flex flex-col justify-between w-full">
                                    <div className="w-[100%]">
                                      <KitBuilderDropDown
                                        data={
                                          builder?.buildKit?.items?.filter(
                                            (data) => data.id === row.id,
                                          )?.[0]?.lots
                                        }
                                        placeholder={
                                          row.lots?.lotNumber || "select"
                                        }
                                        options={row.lots}
                                        setFieldValueFull={(
                                          _,
                                          dataSelected,
                                        ) => {
                                          setActiveloadData(
                                            activeLotData?.map((lotr) => {
                                              if (lotr.id === row.id) {
                                                return {
                                                  ...lotr,
                                                  lots: dataSelected,
                                                };
                                              } else {
                                                return lotr;
                                              }
                                            }),
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className="flex items-center gap-0 mb-0 meta table_lable_hed">
                                      <HemaLabel text="" />
                                      <HemaValue text={""} />
                                    </div>
                                    <div className="flex items-center gap-[8px] meta mb-[5] table_lable_hed">
                                      <HemaLabel
                                        text="Storage Location:"
                                        color="text-[#232323]"
                                        textSize="text-[12px]"
                                        className="font-normal"
                                      />
                                      <HemaValue
                                        text={row.lots?.storageLocation?.name}
                                        color="text-[#595959]"
                                        className="text-xs font-medium"
                                      />
                                    </div>
                                  </div>
                                );
                              },
                            },
                          ]}
                          customStyles={{
                            responsiveWrapper: {
                              style: { overflow: "visible !important" },
                            },
                          }}
                        />
                      </div>
                    ) : (
                      <div className="mt-3">
                        <Skeleton count="4" />
                      </div>
                    )}
                    <div className="py-[16px]">
                      <HemaLabel
                        type={"table"}
                        text={"Storage Location"}
                        icon={<LabelQuantity purple />}
                        className={`flex`}
                        fluid={true}
                        required={true}
                      />
                    </div>
                    <div className="flex items-center justify-between">
                      <HemaValue text={values?.locationId?.name} className="" />
                      {values?.locationId?.name && (
                        <span
                          onClick={() => {
                            setFieldTouched("locationId", true);
                            setFieldValue("locationId", "");
                          }}
                          className={`cursor-pointer`}
                        >
                          <CrossIcon />
                        </span>
                      )}
                    </div>
                    <div className="mb-[16px]">
                      <Button
                        type="button"
                        cta={() => {
                          setShowStorage(true);
                        }}
                        text={"Browse"}
                        className="w-full text-center border-2 border-[#605DAF] flex justify-center text-[#605DAF] "
                      />
                      {errors.locationId && touched.locationId && (
                        <div className="error text-[red] text-[12px] pt-[2px]">
                          {errors.locationId}
                        </div>
                      )}
                    </div>

                    <div className="mb-[16px]">
                      <HemaLabel
                        type={"table"}
                        text={"Close To Expiry Alert"}
                        icon={<ExpiryNotification purple />}
                        className={`flex`}
                        fluid={true}
                      />

                      <FormSwitch
                        name={"closeToExpiryAlert"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.closeToExpiryAlert}
                        // options={fields.options}
                        setFieldValue={setFieldValue}
                        initialValue={values.closeToExpiryAlert}
                        values={values}
                      />
                    </div>
                    <div className="flex flex-col gap-[15px] mb-3">
                      <HemaLabel
                        type={"table"}
                        text={"Alert Me Before (Enter Days)"}
                        icon={<LabelQuantity purple />}
                        className={`flex`}
                        fluid={true}
                        required={values.closeToExpiryAlert ? true : false}
                      />
                      <div className="flex gap-[15px] items-center">
                        <FormText
                          type="number"
                          name={"closeToExpiryNumberOfDays"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          value={values.closeToExpiryNumberOfDays}
                          placeholder={"Enter Number of Days"}
                          disabled={!values?.closeToExpiryAlert}
                          // showTotal={fields.showTotal}
                          // onClick={fields.onClick}
                          // crossIcon={values?.[fields.name] ? <CrossIcon /> : ''}
                          className="w-full"
                        />
                      </div>
                      {errors.closeToExpiryNumberOfDays &&
                        touched.closeToExpiryNumberOfDays && (
                          <div className="error text-[red] text-[12px] pt-[2px]">
                            {errors.closeToExpiryNumberOfDays}
                          </div>
                        )}
                    </div>
                    <div className="flex flex-col gap-[15px] ">
                      <HemaLabel
                        type={"table"}
                        text={"Send Alert To"}
                        icon={<UserGroup purple />}
                        className={`flex`}
                        fluid={true}
                        required={values.closeToExpiryAlert ? true : false}
                      />
                      <div className="w-full">
                        <FormMultiSelect
                          formikRef={formikRef}
                          options={settings?.systemUsers
                            ?.filter((active) => active.isActive)
                            ?.map((data) => {
                              return {
                                id: data.id,
                                name: data.firstName + " " + data.lastName,
                              };
                            })}
                          placeholder={"Select user(s)"}
                          setFieldValue={setFieldValue}
                          name={"userIds"}
                          value={values?.userIds}
                          disabled={!values.closeToExpiryAlert ? true : false}
                        />
                        {errors.userIds && touched.userIds && (
                          <div className="error text-[red] text-[12px] pt-[2px]">
                            {errors.userIds}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
            <div className="flex gap-[8px] justify-end my-[20px]">
              {confirmSlide && (
                <Button
                  cta={async () => {
                    setLoader(true);
                    const result = await printKitLabelAction(
                      uisettings.editForm?.kitId || uisettings.editForm?.id,
                    );
                    setLoader(false);
                    if (result.status === 200) {
                      downloadAsPDF(
                        result.data,
                        uisettings?.editForm?.name + "-kit-label.pdf",
                      );
                    }
                  }}
                  type="button"
                  text={"Print Kit Label"}
                  bg="bg-primary1"
                  border="border-primary1"
                  color="text-white"
                  icon={
                    !loader ? <Print color="#ffffff" /> : <Spinner size="sm" />
                  }
                />
              )}
              <Button
                cta={() => {
                  dispatch(setForm({ state: false, type: "" }));
                }}
                type="button"
                text={confirmSlide ? "Close" : "Cancel"}
                bg="bg-white"
                border="border-primary1"
                color="text-primary1"
                icon={<Cancel />}
              />

              {showBuildQty && !confirmSlide && (
                <Dropdown className="hemato-dropdown-btn">
                  <Dropdown.Toggle variant="" id="dropdown-basic">
                    {!loader ? <Add color="#ffffff" /> : <Spinner size="sm" />}{" "}
                    Generate Picklist
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={async () => {
                        setLoader(true);
                        setisBuild(true);
                        const result = await downloadDocumentAction(
                          uisettings.editForm?.id,
                          "excel",

                          {
                            qty: uisettings?.editForm?.selectedQuantity,
                            items: activeLotData?.map((data) => {
                              return {
                                id: data.lots.id,
                              };
                            }),
                          },
                        );

                        setLoader(false);
                        if (result.status === 200) {
                          saveAsXlsxFile(
                            result.data,
                            uisettings?.editForm?.name + ".xlsx",
                          );
                        }
                      }}
                    >
                      Download Excel
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={async () => {
                        setLoader(true);
                        setisBuild(true);
                        const result = await downloadDocumentAction(
                          uisettings.editForm?.id,
                          "pdf",
                          {
                            qty: uisettings?.editForm?.selectedQuantity,
                            items: activeLotData?.map((data) => {
                              return {
                                id: data.lots.id,
                              };
                            }),
                          },
                        );

                        setLoader(false);
                        if (result.status === 200) {
                          downloadAsPDF(
                            result.data,
                            uisettings?.editForm?.name + ".pdf",
                          );
                        }
                      }}
                    >
                      Download PDF
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
              {!confirmSlide &&
                (uisettings.formLoader ? (
                  <Button
                    type="submit"
                    bg="bg-primary1"
                    text={
                      <>
                        <Spinner animation="grow" size="sm" variant="light" />
                        <Spinner animation="grow" size="sm" variant="light" />
                        <Spinner animation="grow" size="sm" variant="light" />
                      </>
                    }
                  />
                ) : (
                  <Button
                    type="submit"
                    text={"Build"}
                    bg="bg-primary1"
                    color="text-white"
                    disabled={isBuild ? false : true}
                    icon={<Add color="white" />}
                  />
                ))}
            </div>
          </form>
          {showStorage && (
            <div className="absolute top-[170px] z-30 h-screen w-full bg-white ">
              <StorageLocationForm
                controller={formikRef}
                setShowStorage={setShowStorage}
              />
            </div>
          )}
        </>
      )}
    </Formik>
  );
};
export default EditKitBuilder;
