import React, { useEffect, useRef, useState } from 'react';
import { FirstName, Barcode, Confirm } from '../HemeIconLibrary';
import { HemaLabel, Button, SingleSelect } from '../utils';
import HemaHeadingWithSubText from '../utils/HemaHeadingWithSubText';
import { useSelector } from 'react-redux';
import {
    getAllSponserAction,
    getAllSponserStudiesAction,
} from "../Actions/sponsers";
import { downloadSuppliesReportAction } from '../Actions/study'

const SuppliesReport = () => {

    const { sponsers } = useSelector((state) => state);

    const [study, setStudy] = useState();
    const [sponsor, setSponsor] = useState();

    useEffect(() => {
        getAllSponserAction();
    }, []);

    useEffect(() => {
        if (Number.isInteger(sponsor) && sponsor != 0) {
            console.log('spnsr', sponsor)
            getAllSponserStudiesAction(sponsor);
        }
    }, [sponsor]);

    const handleClick = async () => {
        try {
            console.log('Number.isInteger(study) && study !=0', Number.isInteger(study), study != 0)
            if (Number.isInteger(study) && study != 0)
                await downloadSuppliesReportAction(study);
        } catch (error) {
            console.error('Download failed:', error);
        }
    };

    const setFieldValue = (x, id) => {
         setSponsor(id)
        if (Number.isInteger(id) && id != 0)
            getAllSponserStudiesAction(id);
    }

    const setStudyFieldValue = (x, id) => {
        setStudy(id)
    }

    return (
        <>
            <div className="flex gap-[10px] justify-between items-end">
                <HemaHeadingWithSubText heading="Supplies Report" sub="Select the sponser and study to get your supplies report" />
            </div>
            <div className="bg-white rounded-[5px] pt-[16px] pb-[10px] px-4 mt-[27px] mb-[13px] inventory-tabs">
                <div className={`flex items-start gap-[16px] mb-3`}>
                    <div className={`w-full `}>
                        <HemaLabel
                            text="Sponsor"
                            className={'mb-[10px]'}
                            required={true}
                            icon={<FirstName />}
                        />
                        {(
                            <>
                                <SingleSelect
                                    setFieldValue={setFieldValue}
                                    value={sponsor}
                                    options={sponsers?.allSponsers?.filter((data) => data.isActive) || []}

                                />
                            </>
                        )}
                    </div>

                    <div className={`w-full`}>
                        <HemaLabel
                            text="Study #"
                            className={'mb-[10px]'}
                            required={true}
                            icon={<Barcode purple />}
                        />

                        <SingleSelect
                            value={study? study : null}
                            options={sponsers?.sponerStudy?.filter((data) => data?.logisticOrchestratorQcApproved) || []}
                            setFieldValue={setStudyFieldValue}
                        />
                    </div>

                </div>
                <Button
                    type="button"
                    text="Generate"
                    bg="bg-primary1"
                    color="text-white"
                    icon={<Confirm />}
                    disabled={(study && sponsor) ? false : true}
                    cta={handleClick}
                />
            </div>


        </>
    );
};
export default SuppliesReport;